import React, { useEffect, useRef, useState } from 'react'
import * as faceapi from "face-api.js";
import genders from '../data/genders';
import isEmpty from '../helpers/isEmpty';

function BasicInfo({ setStep, data, setData, img, setImg }) {
    const [sname, setName] = useState(data.name);
    const [dob, setDob] = useState(data.dob);
    const [gender, setGender] = useState(data.gender ? data.gender : genders[0]);
    const [occupation, setOccupation] = useState(data.occupation);
    const [ofAddress, setOfAddress] = useState(data.ofAddress);

    const [prefDay, setPrefDay] = useState();

    const [monday, setMonday] = useState(data.days?.includes("Monday") ? true : false);
    const [friday, setFriday] = useState(data.days?.includes("Friday") ? true : false);
    const [thursday, setThursday] = useState(data.days?.includes("Thursday") ? true : false);

    const [saturday, setSaturday] = useState(data.days?.includes("Saturday") ? true : false);
    const [sunday, setSunday] = useState(data.days?.includes("Sunday") ? true : false);

    const [sewadarImage, setSewadarImage] = useState("");

    const back = () => {
        setStep(1)
    }
    console.log("Imgmf", img);

    // console.log(initializing)

    // const canvasRef = useRef();
    // const imageRef = useRef();

    // I want to store cropped image in this state
    // const [pic, setPic] = useState(img ? img : "");

    // useEffect(() => {
    //     const loadModels = async () => {
    //         setInitializing(true);
    //         Promise.all([
    //             // models getting from public/model directory
    //             await faceapi.nets.tinyFaceDetector.load("/models"),
    //             await faceapi.nets.faceLandmark68Net.load("/models"),
    //             await faceapi.nets.faceRecognitionNet.load("/models"),
    //             await faceapi.nets.faceExpressionNet.load("/models")
    //         ])
    //             .then(console.log("success", "/models"))
    //             .then(handleImageClick)
    //             .catch((e) => console.error(e));
    //     };
    //     loadModels();
    // }, [sewadarImage]);

    // const handleImageClick = async () => {
    //     console.log("dd")
    //     if (initializing) {
    //         setInitializing(false);
    //     }
    //     canvasRef.current.innerHTML = faceapi.createCanvasFromMedia(
    //         imageRef.current
    //     );
    //     const displaySize = {
    //         width: 500,
    //         height: 350
    //     };
    //     faceapi.matchDimensions(canvasRef.current, displaySize);
    //     const detections = await faceapi.detectSingleFace(
    //         imageRef.current,
    //         new faceapi.TinyFaceDetectorOptions()
    //     );
    //     // .withFaceLandmarks();
    //     const resizeDetections = faceapi.resizeResults(detections, displaySize);
    //     canvasRef.current
    //         .getContext("2d")
    //         .clearRect(0, 0, displaySize.width, displaySize.height);
    //     faceapi.draw.drawDetections(canvasRef.current, resizeDetections);
    //     // faceapi.draw.drawFaceLandmarks(canvasRef.current, resizeDetections);
    //     console.log(
    //         `Width ${detections.box._width} and Height ${detections.box._height}`
    //     );
    //     extractFaceFromBox(imageRef.current, detections.box);
    //     console.log(detections);
    // };

    // async function extractFaceFromBox(imageRef, box) {
    //     const regionsToExtract = [
    //         new faceapi.Rect(box.x-60, box.y-150, box.width+100, box.height+200)
    //     ];
    //     let faceImages = await faceapi.extractFaces(imageRef, regionsToExtract);

    //     if (faceImages.length === 0) {
    //         console.log("No face found");
    //     } else {
    //         const outputImage = "";
    //         setPic(faceImages[0].toDataURL())
    //     }
    // }

    // console.log(pic);


    const submit = () => {
        if (isEmpty(sname) && isEmpty(dob) && isEmpty(occupation) && isEmpty(ofAddress)) {
            let days = [];

            if (monday) {
                days.push("Monday")
            }
            if (friday) {
                days.push("Friday")
            }
            if (thursday) {
                days.push("Thursday")
            }
            if (saturday) {
                days.push("Saturday")
            }
            if (sunday) {
                days.push("Sunday")
            }
            // setImg(sewadarImage);
            setData({ ...data, name: sname, dob: dob, gender: gender, occupation: occupation, ofAddress: ofAddress, days: days })
        }
        setStep(3)
    }

    const setCorrectGender = (e) => {
        setGender(e.target.value);
    }

    useEffect(() => {
        // if (isEmpty(sname) && isEmpty(dob) && isEmpty(occupation) && isEmpty(ofAddress)) {
            let days = [];

            if (monday) {
                days.push("Monday")
            }
            if (friday) {
                days.push("Friday")
            }
            if (thursday) {
                days.push("Thursday")
            }
            if (saturday) {
                days.push("Saturday")
            }
            if (sunday) {
                days.push("Sunday")
            }
            // setImg(sewadarImage);
            setData({ ...data, name: sname, dob: dob, gender: gender, occupation: occupation, ofAddress: ofAddress, days: days })
        // }
        // setStep(2)
    }, [sname, dob, gender, occupation, ofAddress, monday, thursday, friday, saturday, sunday]);



    console.log("img", img);

    return (
        <div>
            <div class=" bg-gray-100 rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0">
                <h2 class="text-gray-900 text-lg font-medium title-font mb-5">Enter the Basic Info</h2>
                <div class="relative mb-4">
                    <label for="full-name" class="leading-7 text-sm text-gray-600">Name*</label>
                    <input value={sname} onChange={(e) => { setName(e.target.value) }} type="text" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                </div>
                <div class="relative mb-4">
                    <label for="full-name" class="leading-7 text-sm text-gray-600">Date of Birth*</label>
                    <input value={dob} onChange={(e) => { setDob(String(e.target.value)) }} type="date" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                </div>

                <div class="relative mb-4">
                    <label for="full-name" class="leading-7 text-sm text-gray-600">Gender*</label>
                    {/* <input value={dob} onChange={(e) => { setDob(String(e.target.value)) }} type="date" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" /> */}
                    <select value={gender} class="w-full bg-white rounded border h-12 border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" aria-label="What's your gender" onChange={setCorrectGender}>
                        {genders.map((gender) => {
                            return (
                                <option value={gender} >{gender}</option>
                            )
                        })}
                    </select>
                </div>



                <div class="relative mb-4">
                    <label for="full-name" class="leading-7 text-sm text-gray-600">Occupation*</label>
                    <input value={occupation} onChange={(e) => { setOccupation(e.target.value) }} type="text" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                </div>

                <div class="relative mb-4">
                    <label for="full-name" class="leading-7 text-sm text-gray-600">Office Address*</label>
                    <input value={ofAddress} onChange={(e) => { setOfAddress(e.target.value) }} type="text" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                </div>


                <div class="relative mb-">
                    <label for="full-name" class="leading-7 text-sm text-gray-600">Preferred Days*</label>

                    <div className='items-center'>
                        <label className='text-xl mr-3'>Monday</label>
                        <input checked={monday} onChange={() => { setMonday(!monday) }} type="checkbox" class="bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                    </div>



                    <div className='items-center'>
                        <label className='text-xl mr-3'>Thursday</label>
                        <input checked={thursday} onChange={() => { setThursday(!thursday) }} type="checkbox" class="bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                    </div>

                    <div className='items-center'>
                        <label className='text-xl mr-3'>Friday</label>
                        <input checked={friday} onChange={() => { setFriday(!friday) }} type="checkbox" class="bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                    </div>

                    <div className='items-center'>
                        <label className='text-xl mr-3'>Saturday</label>
                        <input checked={saturday} onChange={() => { setSaturday(!saturday) }} type="checkbox" class="bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                    </div>

                    <div className='items-center'>
                        <label className='text-xl mr-3'>Sunday</label>
                        <input checked={sunday} onChange={() => { setSunday(!sunday) }} type="checkbox" class="bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                    </div>


                </div>


                <div class="relative mt-6 mb-4">
                    <label for="full-name" class="leading-7 text-sm text-gray-600">Photo*</label>
                    <input onChange={(e) => { setSewadarImage(e.target.files[0]); setImg(e.target.files[0]); }} type="file" accept="image/png, image/jpg, image/jpeg" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                    {sewadarImage ? <button onClick={() => setSewadarImage("")} class="text-white bg-red-500 border-0 py-2 px-8 mt-6 focus:outline-none hover:bg-red-600 rounded text-lg">Clear</button> : null}
                </div>

                {img ? <img src={URL.createObjectURL(img)} class="xl:w-1/4 lg:w-1/3 md:w-1/2 w-2/3 block mx-auto mb-10 object-cover object-center rounded" style={{ maxWidth: "150px" }} /> : sewadarImage ? <img src={URL.createObjectURL(sewadarImage)} class="xl:w-1/4 lg:w-1/3 md:w-1/2 w-2/3 block mx-auto mb-10 object-cover object-center rounded" style={{ maxWidth: "150px" }} /> : null}

                <div className='text-center'>
                    {/* {isEmpty(sname) && (monday || thursday || friday || saturday || sunday) && isEmpty(dob) && isEmpty(occupation) && isEmpty(ofAddress) && sewadarImage ? <h2 className='font-bold'>Face Image is still processing, Please wait or try again with another image.</h2> : null} */}

                </div>

                <div className='md:flex block justify-around text-center'>
                    <button onClick={back} class="text-white bg-blue-500 border-0 py-2 px-8 focus:outline-none hover:bg-blue-600 rounded text-lg">Back</button>
                    <br />
                    <br />
                    <br />
                    {isEmpty(sname) && (monday || thursday || friday || saturday || sunday) && isEmpty(dob) && (sewadarImage || img) && isEmpty(occupation) && isEmpty(ofAddress) ? <button onClick={submit} class="text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">Next</button> : null}
                </div>
            </div>
        </div>
    )
}

export default BasicInfo