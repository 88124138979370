const isEmpty = (str) => {
    if(str === undefined){
        return false
    }
    else{
        if((str.length === 0 || !str.trim()) === false){
            return true
        }
        else{
            return false
        }
    }
}

export default isEmpty