import isEmpty from "./isEmpty";

function phoneCheck(phoneNum) {
    if (isEmpty(String(phoneNum))) {
        var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

        return re.test(phoneNum);
    }
    else {
        return false;
    }
}
export default phoneCheck;