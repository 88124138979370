import { signOut } from 'firebase/auth';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import BasicInfo from './components/BasicInfo';
import Completion from './components/Completion';
import EmRef from './components/EmRef';
import Phone from './components/Phone';

import UserInfo from './components/UserInfo';
import { auth, db } from './firebase';

function App() {
  const [step, setStep] = useState(1);
  const [data, setData] = useState({});
  const [img, setImg] = useState();
  const [user, setUser] = useState(null);
  const [sewadars, setSewadars] = useState([]);
  const [entries, setEntries] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        if(authUser.displayName == "registered"){
          await setUser(authUser);
          getSewadars();
          getEntries();
          setLoading(false);
        }
        else{
          await setUser(null);
          getSewadars();
          getEntries();
          setLoading(false);
        }
        //   await getSewadars();
        // await getEntries();
        // setLoading(false);
      }
      else {
        await setUser(null);
        getSewadars();
          getEntries();
        setLoading(false);  
      }
    });
  }, []);
  // useEffect(() => {
  //   const run = async () => {
  //     await getSewadars();
  //     await getEntries();
  //     setLoading(false);
  //   }
  //   run();
  // }, []);
  const getSewadars = async () => {

    const sewadarsData = await query(collection(db, 'sewadars'), orderBy("createdAt", "asc"));
    onSnapshot(sewadarsData, (querySnapshot) => {
      setSewadars(querySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
      })))
    });
  }
  const getEntries = async () => {

    const entriesData = await query(collection(db, 'entries'), orderBy("createdAt", "asc"));
    onSnapshot(entriesData, (querySnapshot) => {
      setEntries(querySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
      })))
    });
  }

  console.log(sewadars);

  const getRecord = () => {
    const fSewadars = sewadars?.filter((e) => {
      let sPhone = String(e?.phoneNum)?.replace("+91", "");
      sPhone = sPhone.trim();

      let dPhone = String(user.phoneNumber)?.replace("+91", "");
      dPhone = dPhone.trim();

      return String(sPhone) === String(dPhone)
    })
    const fEntries = entries?.filter((e) => {
      let sPhone = String(e?.phoneNum)?.replace("+91", "");
      sPhone = sPhone.trim();

      let dPhone = String(user.phoneNumber)?.replace("+91", "");
      dPhone = dPhone.trim();

      return String(sPhone) === String(dPhone)
    })
    const logout = () => {
      signOut(auth).then(() => {
        window.location.reload();
        // Sign-out successful.
      }).catch((error) => {
        // An error happened.
      });
    }
    if (fSewadars.length > 0) {
      let sewadar = fSewadars[0];
      return (
        <div>
          <section class="text-gray-600 body-font">
            <div class="container mx-auto flex flex-col px-5 py-24 justify-center items-center">
              {sewadar?.sewadarImage ? <img class="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded" alt="hero" src={sewadar?.sewadarImage} /> : null}
              <div class="w-full md:w-2/3 flex flex-col mb-16 items-center text-center">
                <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">{sewadar?.name}</h1>
                
                <div className='flex items-center justify-center mb-4'>
                  <img src={require("./assets/flag.png")} className="h-12 rounded-md mr-4" />
                  <p>{sewadar?.phoneNum}</p>
                  
                  
                </div>
                <div className='mb-4'>
                {sewadar?.role && sewadar?.department ? <p>{sewadar?.department} - {sewadar?.role}</p> : <p>Sewa not assigned yet</p>}

                </div>
                <button onClick={logout} class="inline-flex text-white bg-red-500 border-0 py-2 px-6 focus:outline-none mb-4 hover:bg-red-600 rounded text-lg">Logout</button>
                {/* table */}


                <section class="text-gray-600 body-fonwt w-full">
                  <div class="container w-full">
                    <div class="w-full mx-auto overflow-auto">
                      <table class="table-auto w-full text-left whitespace-no-wrap">
                        <thead>
                          <tr>
                            <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100 rounded-tl rounded-bl">Gender</th>
                            <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">DOB</th>
                            <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">Address</th>
                            <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">Occupation</th>
                            <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">Office Address</th>

                            {/* <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">Knows About Guruji Since</th> */}
                            {/* <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">In Sewa Since</th> */}
                            {/* <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">Emergency Contact Name</th> */}
                            {/* <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">Emergency Contact Phone</th> */}
                            {/* <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">Reference 1 Contact Name</th> */}
                            {/* <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">Reference 1 Contact Phone</th> */}
                            {/* <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">Reference 2 Contact Name</th> */}
                            {/* <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">Reference 2 Contact Phone</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="px-4 py-3">{sewadar?.gender || "NA"}</td>
                            <td class="px-4 py-3">{sewadar?.dob || "NA"}</td>
                            <td class="px-4 py-3">{sewadar?.address || "NA"}</td>
                            <td class="px-4 py-3">{sewadar?.occupation || "NA"}</td>
                            <td class="px-4 py-3">{sewadar?.ofAddress || "NA"}</td>
                          </tr>

                        </tbody>
                      </table>
                      <hr />
                      <table class="mt-4 table-auto w-full text-left whitespace-no-wrap">
                        <thead>
                          <tr>

                            <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">Knows About Guruji Since</th>
                            <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">In Sewa Since</th>
                            <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">Emergency Contact Name</th>
                            <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">Emergency Contact Phone</th>
                            {/* <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">Reference 1 Contact Name</th> */}
                            {/* <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">Reference 1 Contact Phone</th> */}
                            {/* <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">Reference 2 Contact Name</th> */}
                            {/* <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">Reference 2 Contact Phone</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="px-4 py-3">{sewadar?.knAGuruji || "NA"}</td>
                            <td class="px-4 py-3">{sewadar?.inSewaSince || "NA"}</td>
                            <td class="px-4 py-3">{sewadar?.emCon || "NA"}</td>
                            <td class="px-4 py-3">{sewadar?.emPho || "NA"}</td>
                          </tr>

                        </tbody>
                      </table>


                    </div>
                  </div>
                </section>

                {/* table */}








                <p class="mb-8 leading-relaxed">The following information was provided at the time of registering, if you believe that there's an error in the info, please contact the GKAMM SST Team for making changes.</p>
                <div class="flex w-full justify-center items-end">
                  <div class="relative mr-4 lg:w-full xl:w-1/2 w-2/4 md:w-full text-left">
                  </div>
                  {/* <button class="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">Register for Event</button> */}
                </div>
              </div>




            </div>
          </section>
        </div>
      )
      // setUserData(fSewadars[0]);
    }
    else {
      let sewadar = fEntries[0];
      console.log("My sewadar")
      return (
        <div>
          <section class="text-gray-600 body-font">
            <div class="container mx-auto flex flex-col px-5 py-24 justify-center items-center">
              {sewadar?.sewadarImage ? <img class="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded" alt="hero" src={sewadar?.sewadarImage} /> : null}
              <div class="w-full md:w-2/3 flex flex-col mb-16 items-center text-center">
                <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">{sewadar?.name}</h1>
                <div className='flex items-center justify-center mb-4'>
                  <img src={require("./assets/flag.png")} className="h-12 rounded-md mr-4" />
                  <p>{sewadar?.phoneNum}</p>
                  
                </div>
                <div className='mb-4'>
                {sewadar?.role && sewadar?.department ? <p>{sewadar?.department} - {sewadar?.role}</p> : <p>Sewa not assigned yet</p>}

                </div>

                <button onClick={logout} class="inline-flex text-white bg-red-500 border-0 py-2 px-6 focus:outline-none mb-4 hover:bg-red-600 rounded text-lg">Logout</button>
                {/* table */}


                <section class="text-gray-600 body-fonwt w-full">
                  <div class="container w-full">
                    <div class="w-full mx-auto overflow-auto">
                      <table class="table-auto w-full text-left whitespace-no-wrap">
                        <thead>
                          <tr>
                            <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100 rounded-tl rounded-bl">Gender</th>
                            <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">DOB</th>
                            <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">Address</th>
                            <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">Occupation</th>
                            <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">Office Address</th>

                            {/* <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">Knows About Guruji Since</th> */}
                            {/* <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">In Sewa Since</th> */}
                            {/* <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">Emergency Contact Name</th> */}
                            {/* <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">Emergency Contact Phone</th> */}
                            {/* <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">Reference 1 Contact Name</th> */}
                            {/* <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">Reference 1 Contact Phone</th> */}
                            {/* <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">Reference 2 Contact Name</th> */}
                            {/* <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">Reference 2 Contact Phone</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="px-4 py-3">{sewadar?.gender || "NA"}</td>
                            <td class="px-4 py-3">{sewadar?.dob || "NA"}</td>
                            <td class="px-4 py-3">{sewadar?.address || "NA"}</td>
                            <td class="px-4 py-3">{sewadar?.occupation || "NA"}</td>
                            <td class="px-4 py-3">{sewadar?.ofAddress || "NA"}</td>
                          </tr>

                        </tbody>
                      </table>
                      <hr />
                      <table class="mt-4 table-auto w-full text-left whitespace-no-wrap">
                        <thead>
                          <tr>

                            <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">Knows About Guruji Since</th>
                            <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">In Sewa Since</th>
                            <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">Emergency Contact Name</th>
                            <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">Emergency Contact Phone</th>
                            {/* <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">Reference 1 Contact Name</th> */}
                            {/* <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">Reference 1 Contact Phone</th> */}
                            {/* <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">Reference 2 Contact Name</th> */}
                            {/* <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">Reference 2 Contact Phone</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="px-4 py-3">{sewadar?.knAGuruji || "NA"}</td>
                            <td class="px-4 py-3">{sewadar?.inSewaSince || "NA"}</td>
                            <td class="px-4 py-3">{sewadar?.emCon || "NA"}</td>
                            <td class="px-4 py-3">{sewadar?.emPho || "NA"}</td>
                          </tr>

                        </tbody>
                      </table>


                    </div>
                  </div>
                </section>

                {/* table */}








                <p class="mb-8 leading-relaxed">The following information was provided at the time of registering, if you believe that there's an error in the info, please contact the GKAMM SST Team for making changes.</p>
                <div class="flex w-full justify-center items-end">
                  <div class="relative mr-4 lg:w-full xl:w-1/2 w-2/4 md:w-full text-left">
                  </div>
                  {/* <button class="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">Register for Event</button> */}
                </div>
              </div>




            </div>
          </section>
        </div>
      )
      // setUserData(fEntries[0]);
    }


  }
  return (
    <div>
      <section class="text-gray-600 body-font">
        {!loading ? user === null ? <div class="container px-5 py-24 mx-auto flex flex-wrap flex-col">
          {step !== 4 ? <img class="xl:w-1/4 lg:w-1/3 md:w-1/2 w-2/3 block mx-auto mb-10 object-cover object-center rounded" alt="hero" src={require("./assets/guruji.jpeg")} /> : null}
          <div class="flex flex-col  w-full">
            {step == 1 ? <Phone sewadars={sewadars} entries={entries} setStep={setStep} data={data} setData={setData} /> : null}
            {step == 2 ? <BasicInfo setStep={setStep} data={data} img={img} setImg={setImg} setData={setData} /> : null}
            {step == 3 ? <EmRef setStep={setStep} data={data} setData={setData} /> : null}
            {step == 4 ? <Completion data={data} img={img} /> : null}
          </div>
        </div> : <div>
          {/* <h1 className='text-4xl text-center text-black font-extrabold'>You are a Registered Sewadar</h1> */}
          {sewadars ? getRecord() : null}
          {/*  {!loading ? <UserInfo sewadars={sewadars} entries={entries} user={user} /> : null} */}

        </div> : <div>
        <img className='mr-auto ml-auto block' src={require("./assets/loading.gif")} />
        </div>}
      </section>
    </div>
  )
}

export default App