import { serverTimestamp } from 'firebase/firestore';
import React, { useState } from 'react'
import genders from '../data/genders';
import isEmpty from '../helpers/isEmpty';
import phoneCheck from '../helpers/phoneCheck';
import bloodGroups from '../data/bloodg';
import { useEffect } from 'react';

function EmRef({ setStep, data, setData }) {
    const [address, setAddress] = useState(data.address);
    const [knAGuruji, setKnAGuruji] = useState(data.knAGuruji);
    const [inSewaSince, setInSewaSince] = useState(data.inSewaSince);
    const [emCon, setEmCon] = useState(data.emCon);
    const [emPho, setEmPho] = useState(data.emPho);
  
    const [ref1Con, setRef1Con] = useState(data.ref1Con ? data.ref1Con : "");
    const [ref1Pho, setRef1Pho] = useState(data.ref1Pho ? data.ref1Pho : null);
  
    const [ref2Con, setRef2Con] = useState(data.ref2Con ? data.ref2Con : "");
    const [ref2Pho, setRef2Pho] = useState(data.ref2Pho ? data.ref2Pho : null);

    const [remarks, setRemarks] = useState("");
    const [agreed, setAgreed] = useState(false);
    
    const [group, setGroup] = useState(data.bloodGroup ? data.bloodGroup : bloodGroups[0]);
    const [health, setHealth] = useState(data.healthIssues ? data.healthIssues : "");
  
    const back = () => {
        setStep(2)
    }

    const submit = () => {
        if (isEmpty(address) && isEmpty(knAGuruji) && isEmpty(inSewaSince) && isEmpty(emCon) && phoneCheck(emPho)) {

            setData({ ...data, bloodGroup: group, healthIssues: health, address: address, knAGuruji: knAGuruji, inSewaSince: inSewaSince, emCon: emCon, emPho: emPho, ref1Con: ref1Con, ref1Pho: ref1Pho, ref2Con: ref2Con, ref2Pho: ref2Pho, remarks: remarks, createdAt: serverTimestamp() })
        }
        setStep(4)
    }


    useEffect(() => {
        // if (isEmpty(address) && isEmpty(knAGuruji) && isEmpty(inSewaSince) && isEmpty(emCon) && phoneCheck(emPho)) {
            setData({ ...data, bloodGroup: group, healthIssues: health, address: address, knAGuruji: knAGuruji, inSewaSince: inSewaSince, emCon: emCon, emPho: emPho, ref1Con: ref1Con, ref1Pho: ref1Pho, ref2Con: ref2Con, ref2Pho: ref2Pho, remarks: remarks, createdAt: serverTimestamp() })
        // }
    }, [address, knAGuruji, inSewaSince, emCon, emPho, ref1Con, ref1Pho, ref2Con, ref2Pho, remarks, group, health]);
    

    const setCorrectGroup = (e) => {
        setGroup(e.target.value);
    }

    return (
        <div>
            <div class=" bg-gray-100 rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0">
                <h2 class="text-gray-900 text-lg font-medium title-font mb-5">Enter the Additonal Info</h2>
                <div class="relative mb-4">
                    <label for="full-name" class="leading-7 text-sm text-gray-600">Blood Group*</label>
                    <select value={group} class="w-full bg-white rounded border h-12 border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" aria-label="What's your gender" onChange={setCorrectGroup}>
                        {bloodGroups.map((groupg) => {
                            return (
                                <option value={groupg} >{groupg}</option>
                            )
                        })}
                    </select>
                </div>
                <div class="relative mb-4">
                    <label for="full-name" class="leading-7 text-sm text-gray-600">Existing Health Issues</label>
                    <input value={health} onChange={(e) => { setHealth(e.target.value) }} type="text" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                </div>
                <div class="relative mb-4">
                    <label for="full-name" class="leading-7 text-sm text-gray-600">Address*</label>
                    <input value={address} onChange={(e) => { setAddress(e.target.value) }} type="text" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                </div>
                <div class="relative mb-4">
                    <label for="full-name" class="leading-7 text-sm text-gray-600">Knows About Guruji Since(Year)*</label>
                    <input value={knAGuruji} onChange={(e) => { setKnAGuruji(e.target.value) }} type="number" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                </div>

                <div class="relative mb-4">
                    <label for="full-name" class="leading-7 text-sm text-gray-600">In Sewa Since(Year)*</label>
                    <input value={inSewaSince} onChange={(e) => { setInSewaSince(e.target.value) }} type="number" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                </div>

                {/* Emergency */}

                <div class="relative mb-4">
                    <label for="full-name" class="leading-7 text-sm text-gray-600">Emergency Contact Name*</label>
                    <input value={emCon} onChange={(e) => { setEmCon(e.target.value) }} type="text" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                </div>

                <div class="relative mb-4">
                    <label for="full-name" class="leading-7 text-sm text-gray-600">Emergency Contact Phone No*</label>
                    <input value={emPho} onChange={(e) => { setEmPho(e.target.value) }} type="number" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                </div>


                {/* References */}


                <div class="relative mb-4">
                    <label for="full-name" class="leading-7 text-sm text-gray-600">Reference 1 Contact Name</label>
                    <input value={ref1Con} onChange={(e) => { setRef1Con(e.target.value) }} type="text" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                </div>

                <div class="relative mb-4">
                    <label for="full-name" class="leading-7 text-sm text-gray-600">Reference 1 Contact Phone No.</label>
                    <input value={ref1Pho} onChange={(e) => { setRef1Pho(e.target.value) }} type="number" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                </div>


                <div class="relative mb-4">
                    <label for="full-name" class="leading-7 text-sm text-gray-600">Reference 2 Contact Name</label>
                    <input value={ref2Con} onChange={(e) => { setRef2Con(e.target.value) }} type="text" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                </div>

                <div class="relative mb-4">
                    <label for="full-name" class="leading-7 text-sm text-gray-600">Reference 2 Contact Phone No.</label>
                    <input value={ref2Pho} onChange={(e) => { setRef2Pho(e.target.value) }} type="number" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                </div>

                <div class="relative mb-4">
                    <label for="full-name" class="leading-7 text-sm text-gray-600">Remarks/Comments</label>
                    <textarea value={remarks} onChange={(e) => { setRemarks(e.target.value) }} type="text" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                </div>

                <div className='mb-4 items-center text-center'>
                <input checked={agreed} onChange={() => { setAgreed(!agreed) }} type="checkbox" class="mr-4" />
                <label>I verify the details entered above is factually true and correct. I hereby confirm that I'm physically fit to provide assistance or sewa in Mandir Operations.</label>
                </div>



                <div className='md:flex block justify-around text-center'>
                    <button onClick={back} class="text-white bg-blue-500 border-0 py-2 px-8 focus:outline-none hover:bg-blue-600 rounded text-lg">Back</button>
                    <br />
                    <br />
                    {isEmpty(address) && agreed && isEmpty(knAGuruji) && isEmpty(inSewaSince) && isEmpty(emCon) && phoneCheck(emPho) ? <button onClick={submit} class="text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">Next</button> : null}
                </div>
            </div>
        </div>
    )
}

export default EmRef