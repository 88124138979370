import { updateProfile } from 'firebase/auth';
import { addDoc, collection, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable, uploadString } from 'firebase/storage';
import React, { useEffect, useState } from 'react'
import { auth, db, storage } from '../firebase';

function Completion({ data, img }) {
  const [completed, setCompleted] = useState(false);
  
  useEffect(() => {
    const submitEntry = async () => {
      const docRef = await addDoc(collection(db, "entries"), data);
      const storageRef = await ref(storage, `entries/${docRef.id}`);
      await uploadBytesResumable(storageRef, img);
      await getDownloadURL(storageRef).then(async (url) => {
        await updateDoc(docRef, {
          sewadarImage: url,
        }).then(async ()=>{
          await updateProfile(auth?.currentUser, {
            displayName: "registered"
          })
          setCompleted(true);
        });
      })
    }
    if(completed === false){
      submitEntry()
    }
  }, []);

  return (
    <div className='text-center'>
      {completed === false ? <img className='mr-auto ml-auto block' src={require("../assets/loading.gif")} /> : null}
      {completed === true ? <h1 className='text-3xl font-bold text-black'>Sewadar Form Submitted</h1> : null}
      {completed === true ? <video className='mr-auto ml-auto block' src={require("../assets/confirm.mp4")} autoPlay /> : null}
      {completed === true ? <p className='text-xl mt-6'>GKAMM SST Team will be contacting you for further details.</p> : null}
    </div>
  )
}

export default Completion