import React, { useState } from 'react';
import { RecaptchaVerifier, signInWithPhoneNumber, updateProfile } from "firebase/auth";
import { auth } from '../firebase';


function Phone({ setStep, data, setData, sewadars, entries }) {
  const [phone, setPhone] = useState(data.phone);
  const [otp, setOtp] = useState();

  const [sent, setSent] = useState(false);
  const [verified, setVerified] = useState(false);
  const submit = () => {
    if (String(parseInt(otp)).length === 6) {
      let confirmationResult = window.confirmationResult;
      confirmationResult.confirm(parseInt(otp)).then((result) => {
        // User signed in successfully.
        const user = result.user;
        const fSewadars = sewadars?.filter((e) => {
          return e.phoneNum === parseInt(user.phoneNumber.slice(3))
        })
        const fEntries = entries?.filter((e) => {
          return e.phoneNum === parseInt(user.phoneNumber.slice(3))
        })

        if (fSewadars.length !== 0 || fEntries.length !== 0) {
          // That means user is there in the db eventually
          updateProfile(user, {
            displayName: "registered"
          }).then(()=>{
            window.alert("You are a Registered Sewadar");
            window.location.reload();
          })
        }
        else {
          setData({ ...data, phoneNum: phone })
          setStep(2)
        }
        // ...
      }).catch((error) => {
        window.alert("Wrong Verification OTP");
      });
    }
  }



  const generateRecaptcha = () => {
    try {
      window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
        'size': 'invisible',
        'callback': (response) => {
          console.log(response)
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // ...
        },
        'expired-callback': () => {
          // Response expired. Ask user to solve reCAPTCHA again.
          // ...
        }
      }, auth);

    } catch (error) {
      console.log(error);
    }
  }

  const sendOTP = () => {
    // if (String(phone.length === 10)) {
    generateRecaptcha();
    let appVerifier = window.recaptchaVerifier;

    signInWithPhoneNumber(auth, String(`+91 ${phone}`), appVerifier).then((confirmationResult) => {
      // OTP SMS sent
      window.confirmationResult = confirmationResult;
      setSent(true);
    }).catch((error) => {
      console.log(error);
    })

    // }
  }

  return (
    <div>
      <div class=" bg-gray-100 rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0">
        <h2 class="text-gray-900 text-lg font-medium title-font mb-5">Verification</h2>
        <div class="relative mb-4">
          <label for="full-name" class="leading-7 text-sm text-gray-600">Phone Number</label>
          <div className='md:flex items-center text-center hidden'>
            <img className='mr-4 rounded-md' src={require("../assets/flag.png")} width={70} />
            <label className='mr-4'>+91</label>
            <input value={phone} onChange={(e) => {
              if (String(parseInt(e.target.value)).length <= 10) {
                if (parseInt(e.target.value)) {
                  setPhone(parseInt(e.target.value))
                }
                else {
                  setPhone();
                }
              }
            }
            } type="text" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
          </div>


          <div className='md:hidden items-center text-center block'>
            <div className='flex items-center mb-4 justify-center'>
              <img className='rounded-md mr-4' src={require("../assets/flag.png")} width={70} />
              <label className='mr-4'>+91</label>
            </div>
            <input value={phone} onChange={(e) => {
              if (String(parseInt(e.target.value)).length <= 10) {
                if (parseInt(e.target.value)) {
                  setPhone(parseInt(e.target.value))
                }
                else {
                  setPhone();
                }
              }
            }
            } type="text" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
          </div>

        </div>

        {sent ? <div class="relative mb-4">
          <label for="full-name" class="leading-7 text-sm text-gray-600">OTP</label>
          <input value={otp} onChange={(e) => {
            setOtp(e.target.value)
            // if(String(e.target.value).length <= 10){
            // }
          }
          } type="number" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
        </div> : null}


        <div
          id="recaptcha-container"
          class="justify-center flex"
        ></div>
        <div className='md:flex block justify-around text-center'>
          <br />
          <br />
          {!sent ? String(phone).length === 10 ? <button onClick={sendOTP} class="text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">Next</button> : null : null}

          {sent ? <button onClick={submit} class="text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">Submit OTP</button> : null}
        </div>
      </div>
    </div>
  )
}

export default Phone